import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootStateType } from '../redux-store/reducers'; // Import your root state type if you have one
import { featureToggles } from '../redux-store/actions';

export const useFeatureToggles = () => {
  const dispatch = useDispatch();
  const dateFeatureToggles = useSelector(
    (state: RootStateType) => state.toggler.dateFeatureToggles
  );

  useEffect(() => {
    const shouldFetch = Object.values(dateFeatureToggles).some((date) => !date);
    if (shouldFetch) {
      dispatch(featureToggles());
    }
  }, [dateFeatureToggles, dispatch]);

  return dateFeatureToggles;
};
