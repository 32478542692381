import { Typography, Grid, CardMedia, Button, IconButton, SelectChangeEvent } from '@mui/material';
import { Formik, useField, validateYupSchema, yupToFormErrors } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDocuments, updateApplication } from '../../redux-store/actions/application';
import {
  individuals_Poa_nonIndividuals_Documents,
  FileType,
  Document,
  Bank,
  KraDocumentType,
  ApplicationProps,
} from '../../redux-store/types/api-types';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { RootStateType } from '../../redux-store/reducers';
import { Box } from '@mui/system';
import {
  DOCUMENT_ALLOW_PASTE,
  ENABLE_KRA_DOCUMENT_FETCH,
  IMAGE_UPLOAD_FORMATS,
  USER_ROLES,
} from '../../utils/constant';
import { Notes, ProceedSaveLater } from '../investors/components';
import { useHistory } from 'react-router';
import {
  applicationComparison,
  checkIfApplicationIsNonIndividualPOA,
  checkValidationBasedOnDate,
  fatcaDocumentCheck,
  nonIndividualDocuments,
  getDocNumberForMultipleDocs,
  saveForLater,
} from '../../utils/utilityFunctions';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { nonIndividualDocumentDetailsSchema } from '../../utils/schema';
import { logout, showError } from '../../redux-store/actions/auth';
import MFSelectField from '../../lib/formik/SelectField';
import { useSnackbar } from 'notistack';
import { KraDocument } from '../investors/kraDocument';
import { MFTextField } from '../../lib/formik';
import { Gridstyles } from '../ApplicationDetails';
import MFCheckbox from '../../lib/formik/Checkbox';
import { useFeatureToggles } from '../../utils/toggler';

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

type documentDetails = {
  documentType: string;
  documentName: string;
  documentId: string;
  isActive: boolean;
  isVisible: boolean;
  file?: FileType;
  required?: string;
  uniqueKey?: string;
  options?: string[];
  [key: string]: string | number | boolean | FileType | undefined | string[] | null;
};

export type documentProps = {
  documents: {
    documentType: string;
    documentName: string;
    documentsList: documentDetails[];
    multipleFiles: string;
    required: string;
    options?: string[];
  }[];
  kraDocument?: KraDocumentType[];
  fatcaCrsDocumentCheck?: boolean | null;
};
export interface Values {
  ppmNo: string;
  applicants: documentProps[];
  saveType: string;
  banks: Bank[];
  createdAt: string;
  [key: string]: documentProps[] | string | undefined | string[] | Bank[];
}

export const FileUpload = ({
  onFileChange,
  name,
  documentData,
}: {
  onFileChange: (value: string, documentId: string, removeFile?: boolean) => void;
  name: string;
  documentData: documentDetails;
}): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [files, setFiles] = useState<any>([]);
  const history = useHistory();
  const authToken = useSelector((store: RootStateType) => store.auth.token);
  const dispatch = useDispatch();
  const handleFileChange = (name: string, documentId: string) => {
    try {
      onFileChange(name, documentId);
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  return (
    <>
      {!documentData.file ? (
        <Box
          sx={{
            '.filepond--credits': { display: 'none' },
            '.filepond--label-action': {
              textDecoration: 'none',
              fontSize: '14px',
            },
            '.filepond--drop-label': {
              cursor: 'pointer',
              pointerEvents: 'all',
            },
            '.filepond--file': {
              color: '#000',
              flexWrap: 'wrap',
              fontWeight: 500,
            },
          }}>
          <FilePond
            files={files}
            server={{
              process: {
                url: '/api/files/files',
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${authToken}`,
                },
                onload: (response): string => {
                  const result = JSON.parse(response);
                  result?.name && result?.id && handleFileChange(result.name, result.id);
                  return response;
                },
              },
            }}
            onupdatefiles={(fileItems) => {
              setFiles(fileItems);
            }}
            // onremovefile={() => {
            //   onFileChange('', '', true);
            // }}
            onprocessfile={(error, file) => {
              if (error) {
                if (error.code === 401) {
                  dispatch(logout());
                  // history.push('/login');
                }
              }
            }}
            onprocessfilerevert={() => {
              onFileChange('', '', true);
            }}
            name="file"
            maxFileSize={'2MB'}
            allowMultiple={false}
            maxFiles={1}
            acceptedFileTypes={IMAGE_UPLOAD_FORMATS}
            labelIdle='<span class="filepond--label-action">Choose File <div>Max Size: 2MB</div>Supports: jpeg,pdf,png,jpg</span>'
            labelMaxFileSize={'Max file size is 2 MB'}
            labelFileTypeNotAllowed={'Invalid file type'}
            fileValidateTypeLabelExpectedTypesMap={{
              'image/jpeg': '.jpeg',
              'image/jpg': '.jpg',
              'image/png': '.png',
              'application/pdf': '.pdf',
            }}
            imagePreviewHeight={100}
            allowPaste={DOCUMENT_ALLOW_PASTE}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            border: '1px dashed #AAB2BA',
            borderRadius: '6px',
            mb: 2,
            minHeight: '65px',
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CardMedia
              component="img"
              src={documentData.file.thumbnailUrl || '/images/photo-placeholder.jpg'}
              alt="aadhar"
              width="50"
              height="50"
              sx={{ display: 'flex', width: 'unset', mx: 1 }}
            />
          </Box>
          <Typography
            component="span"
            sx={{ whiteSpace: 'pre-wrap', overflow: 'hidden', textOverflow: 'ellipsis', flex: 1 }}>
            {documentData.file.name}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              '.MuiButton-root': { fontWeight: 500, fontSize: 14, p: 0, mr: 1 },
              flexWrap: 'wrap',
            }}>
            <Button
              sx={{
                color: 'primary.main',
                border: '1px dashed #2057A6',
                mb: 1,
              }}
              onClick={() => window.open(documentData.file?.viewUrl)}>
              View
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                onFileChange('', '', true);
              }}
              sx={{ mb: 1 }}>
              Replace
            </Button>
            <IconButton
              sx={{
                p: 0,
                pr: 0.5,
                ':hover': {
                  bgcolor: '#F4FCFC',
                },
                cursor: 'pointer',
                pointerEvents: 'all',
                mb: 1,
              }}
              onClick={() => window.open(documentData.file?.url, '_blank')}>
              <CardMedia
                component="img"
                src={'/images/download-icon.png'}
                alt="Download"
                sx={{ width: '25px' }}
              />
            </IconButton>
          </Box>
        </Box>
      )}
      {!!errorText && (
        <Typography
          sx={{
            color: '#d32f2f',
            fontSize: '0.75rem',
          }}>
          {errorText}
        </Typography>
      )}
    </>
  );
};

export const document_object = {
  documentId: '',
  isActive: true,
};

export default function DocumentDetails(): JSX.Element {
  const [documentsData, setDocumentsData] = useState<individuals_Poa_nonIndividuals_Documents[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async function () {
      try {
        const { applicants = [] } = application || {};
        const getInvestorType = applicants[0]?.investorType?.toString();
        if (getInvestorType) {
          const response = (await dispatch(getDocuments())) as unknown as Document;
          if (getInvestorType && Object.keys(response).includes(getInvestorType)) {
            if (checkIfApplicationIsNonIndividualPOA(application as ApplicationProps)) {
              setDocumentsData(
                response[`${getInvestorType}_poa`] as individuals_Poa_nonIndividuals_Documents[]
              );
            } else {
              setDocumentsData(
                response[getInvestorType] as individuals_Poa_nonIndividuals_Documents[]
              );
            }
          }
        }
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application]);

  const { applicants = [], banks = [], ppmNo = '', hasPOA, createdAt = '' } = application || {};

  const dateFeatureToggles = useFeatureToggles();

  const { bankAccountProofValidation, dematDocumentValidation } = dateFeatureToggles;

  const initialValues: Values = {
    ppmNo,
    applicants: nonIndividualDocuments(application, role, documentsData, dematDocumentValidation),
    saveType: 'save and proceed',
    banks: banks,
    createdAt,
  };

  const handleSubmit = async (values: Values) => {
    const { applicants, saveType, ppmNo } = values;
    const {
      applicants: exisitingApplicants = [],
      id,
      applicant1ReferenceId = '',
      currentStep,
      applicationNumber,
    } = application || {};
    const updatedApplicants = exisitingApplicants.map((applicant, index) => {
      const { documents = [] } = applicants[index] || {};
      const finalDocs = documents.map((doc) => {
        return doc.documentsList.filter((d) => d.documentId);
      });
      return {
        ...applicant,
        documents: finalDocs.flat().map((doc) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { uniqueKey, required, ...rest } = doc;
          return rest;
        }),
        fatcaCrsDocumentCheck: applicants[index].fatcaCrsDocumentCheck,
      };
    });
    const checkApplication = applicationComparison(
      {
        ...application,
        ppmNo: application?.ppmNo,
        applicants: application?.applicants
          ?.map((applicant) => {
            return {
              ...applicant,
              documents: applicant.documents
                ?.map((document: any) => {
                  const {
                    documentData,
                    // id,
                    // applicantId,
                    filename,
                    updatedAt,
                    createdAt,
                    updatedBy,
                    createdBy,
                    ...rest
                  } = document;
                  return { ...rest };
                })
                .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId)),
            };
          })
          .sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
      },
      {
        ...application,
        ppmNo: ppmNo,
        applicants: updatedApplicants
          .map((applicant) => {
            return {
              ...applicant,
              documents: applicant.documents
                ?.map((document: any) => {
                  const { documentData, options, ...rest } = document;
                  return { ...rest };
                })
                .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId)),
            };
          })
          .sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
        currentStep: !!currentStep && currentStep > 6 ? currentStep : Number(currentStep) + 1,
      }
    );
    try {
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              ppmNo: ppmNo,
              applicants: updatedApplicants,
              currentStep: 7,
            },
            applicationId: id,
            ...(saveType !== 'save and proceed' && {
              toastMessage: '',
            }),
          })
        );
        saveType === 'save and proceed'
          ? history.push('authorised-signatory', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      } else if (checkApplication) {
        if (saveType !== 'save and proceed') {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        saveType === 'save and proceed'
          ? history.push('authorised-signatory', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  const getLabel = (
    documentName: string,
    documentType: string,
    required: string,
    values: Values
  ): string => {
    return `${documentName}  ${
      documentType === 'bankAccountProof'
        ? // .filter((bank) => !bank.pennydropVerified && bank.defaultBankAccount)
          values.banks.length !== 0 &&
          checkValidationBasedOnDate(application?.createdAt || '', bankAccountProofValidation)
          ? '*'
          : ''
        : required === 'true'
        ? '*'
        : ''
    } `;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validate={(values: Values) => {
        try {
          validateYupSchema(
            values,
            nonIndividualDocumentDetailsSchema(bankAccountProofValidation),
            true,
            values
          );
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}>
      {({ handleSubmit, values, setValues, setFieldValue }) => (
        <Grid
          container
          rowSpacing={2}
          columnSpacing={5}
          component="form"
          noValidate
          onSubmit={handleSubmit}
          display="flex"
          // alignItems="center"
        >
          <Grid sx={{ ml: '40px', width: '100%' }}>
            <Notes
              displayContent={'Please upload latest documents not more than 2 months old'}
              displayContent1={"Please don't upload any password protected files"}
            />
          </Grid>
          <Gridstyles
            sx={{
              width: '100%',
              pl: { xs: '16%', md: '7%', sm: '7%' },
              pr: { xs: '0%', md: '11%', sm: '11%' },
            }}>
            <Grid container xs={12} sm={6}>
              <MFTextField name="ppmNo" label="PPM Number" placeholder="Enter PPM Number" />
            </Grid>
          </Gridstyles>
          {values.applicants.map((applicant, ind) => {
            const { documents } = applicant;
            let num = 0;
            return (
              <>
                {hasPOA && (
                  <Grid item xs={12}>
                    <MFCheckbox
                      name={`applicants.${ind}.fatcaCrsDocumentCheck`}
                      label={'Wish to upload FATCA-CRS Declaration'}
                      sx={{ my: 2 }}
                      onChange={({ target: { checked } }) => {
                        const findExistingFatca = documents.find(
                          (fatcaDoc) => fatcaDoc.documentType === 'fatca'
                        );
                        const getFatcaFromMasterJson = documentsData.find(
                          (_fatcaDoc) => _fatcaDoc.documentType === 'fatca'
                        );

                        const updatedDoc = findExistingFatca
                          ? documents
                              .map((doc, i) => {
                                const fatcaIndex = documents.findIndex(
                                  (doc) => doc.documentType === 'fatca'
                                );
                                if (i > fatcaIndex) {
                                  return {
                                    ...doc,
                                    documentsList: doc.documentsList.map((_doc, thirdIndex) => {
                                      return {
                                        ..._doc,
                                        isVisible: true,
                                        uniqueKey: (ind.toString() +
                                          '-' +
                                          (i - 1).toString() +
                                          '-' +
                                          thirdIndex) as string,
                                      };
                                    }),
                                  };
                                }
                                return doc;
                              })
                              .filter((document) => {
                                if (
                                  fatcaDocumentCheck(
                                    hasPOA,
                                    !checked as boolean,
                                    document.documentType
                                  )
                                ) {
                                  return;
                                }
                                return document;
                              })
                          : [
                              ...documents,
                              checked && getFatcaFromMasterJson
                                ? {
                                    documentType: getFatcaFromMasterJson.documentType,
                                    documentName: getFatcaFromMasterJson.documentName,
                                    documentsList: [
                                      {
                                        documentType: getFatcaFromMasterJson.documentType,
                                        documentName: getFatcaFromMasterJson.documentName,
                                        required: 'true',
                                        options: getFatcaFromMasterJson.options,
                                        isVisible: true,
                                        uniqueKey: (ind.toString() +
                                          '-' +
                                          documents.length.toString() +
                                          '-' +
                                          '0') as string,
                                        ...document_object,
                                      },
                                    ],
                                    required: 'true',
                                    multipleFiles: getFatcaFromMasterJson.multipleFiles,
                                    options: getFatcaFromMasterJson.options,
                                  }
                                : {},
                            ];
                        setFieldValue(`applicants.${ind}`, {
                          ...applicants[ind],
                          documents: updatedDoc,
                          fatcaCrsDocumentCheck: checked,
                        });
                      }}
                      disabled={[USER_ROLES.POAAPPROVER].includes(role)}
                    />
                  </Grid>
                )}
                {documents.map((document, doc_index) => {
                  num = doc_index + 1;
                  const {
                    documentType,
                    documentName,
                    documentsList = [],
                    multipleFiles,
                    options,
                    required,
                  } = document;
                  return (
                    <>
                      <React.Fragment key={ind}>
                        <Grid item xs={12} sm={6} display="flex" alignItems="baseline">
                          <Typography
                            component="span"
                            sx={{ color: '#2057A6', fontSize: '20px', paddingRight: '15px' }}>
                            {doc_index + 1}
                          </Typography>
                          {!options && (
                            <Typography
                              component="span"
                              sx={{ fontSize: 14, fontWeight: 500, color: 'rgba(0,0,0,0.7)' }}>
                              {documentName} {required === 'true' ? '*' : ''}
                            </Typography>
                          )}
                          {options &&
                            documentsList
                              .filter(
                                (document, index) =>
                                  documentsList.findIndex(
                                    (obj) => document.documentType === obj.documentType
                                  ) === index
                              )
                              .map((doc, index) => (
                                <React.Fragment key={index}>
                                  <Grid
                                    xs={12}
                                    sm={12}
                                    sx={{ position: 'relative', bottom: '15px' }}>
                                    <MFSelectField
                                      name={`applicants.${ind}.documents.${doc_index}.documentsList.${index}.documentName`}
                                      label={getLabel(documentName, documentType, required, values)}
                                      items={options.map((item: string) => ({
                                        key: item,
                                        value: item,
                                      }))}
                                      applyLabelStyles={true}
                                      onChange={({
                                        target: { value },
                                      }: SelectChangeEvent<unknown>) => {
                                        const updatedDocumentList = values.applicants[
                                          ind
                                        ].documents[doc_index].documentsList.map((docList) => {
                                          if (docList.documentType === doc.documentType) {
                                            return {
                                              ...docList,
                                              documentName: value,
                                            };
                                          }
                                          return docList;
                                        });
                                        setFieldValue(
                                          `applicants.${ind}.documents.${doc_index}.documentsList`,
                                          updatedDocumentList
                                        );
                                      }}
                                    />
                                  </Grid>
                                </React.Fragment>
                              ))}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          display="flex"
                          flexDirection="column"
                          mt={options ? { xs: 1, sm: 5 } : 2}>
                          {documentsList
                            .filter((item) => item.isVisible)
                            .map((doc, index) => (
                              <React.Fragment key={doc.uniqueKey}>
                                <FileUpload
                                  onFileChange={(value, documentId, removeFile) => {
                                    const { file, ...rest } =
                                      values.applicants[ind].documents[doc_index].documentsList[
                                        Number(doc?.uniqueKey?.split('-')[2])
                                      ];
                                    setFieldValue(
                                      `applicants.${ind}.documents.${doc_index}.documentsList.${
                                        doc?.uniqueKey?.split('-')[2]
                                      }`,
                                      {
                                        ...rest,
                                        documentId,
                                        ...(!removeFile && { file }),
                                      }
                                    );
                                  }}
                                  name={`applicants.${ind}.documents.${doc_index}.documentsList.${
                                    doc?.uniqueKey?.split('-')[2]
                                  }.documentId`}
                                  documentData={doc}
                                />
                                {index > 0 && doc.isVisible && !doc.documentId && (
                                  <DeleteOutlineIcon
                                    onClick={() => {
                                      const getDocs = values.applicants[ind].documents[
                                        doc_index
                                      ].documentsList.map((_item, i) => ({
                                        ..._item,
                                        isVisible:
                                          i !== Number(doc?.uniqueKey?.split('-')[2])
                                            ? _item.isVisible
                                            : false,
                                      }));
                                      // .filter((_, i) => i !== index);
                                      setFieldValue(
                                        `applicants.${ind}.documents.${doc_index}.documentsList`,
                                        getDocs
                                      );
                                    }}
                                    color="error"
                                    sx={{
                                      alignSelf: 'end',
                                      position: 'relative',
                                      bottom: '90px',
                                      left: '22px',
                                      cursor: 'pointer',
                                    }}
                                  />
                                )}
                              </React.Fragment>
                            ))}
                          {multipleFiles === 'true' &&
                            documentsList.filter((item) => item.isVisible).length <
                              (documentType === 'additional' ? 4 : 2) && (
                              <Typography
                                onClick={() => {
                                  const currentDocTypeListLength =
                                    values.applicants[ind].documents[doc_index].documentsList
                                      .length;
                                  const {
                                    required = true,
                                    documentType,
                                    documentName,
                                    documentId,
                                  } = values.applicants[ind].documents[doc_index].documentsList[
                                    currentDocTypeListLength - 1
                                  ];
                                  try {
                                    documentsList
                                      .filter((item) => item.isVisible)
                                      .forEach((item, _i) => {
                                        if (!item.documentId) {
                                          throw Error(
                                            `Please upload the ${getDocNumberForMultipleDocs(
                                              _i + 1
                                            )}document to add more documents`
                                          );
                                        }
                                      });
                                    setFieldValue(
                                      `applicants.${ind}.documents.${doc_index}.documentsList`,
                                      [
                                        ...values.applicants[ind].documents[doc_index]
                                          .documentsList,
                                        {
                                          ...document_object,
                                          uniqueKey:
                                            ind.toString() +
                                            '-' +
                                            doc_index.toString() +
                                            '-' +
                                            currentDocTypeListLength.toString(),
                                          isVisible: true,
                                          documentType,
                                          documentName,
                                          required,
                                        },
                                      ]
                                    );
                                  } catch (e) {
                                    dispatch(showError((e as Error).message));
                                  }
                                }}
                                sx={{
                                  color: '#417850',
                                  fontSize: '12px',
                                  fontWeight: 500,
                                  textDecoration: 'underline',
                                  alignSelf: 'end',
                                  // position: 'relative',
                                  // bottom:
                                  //   values.applicants[ind].documents[doc_index].documentsList[
                                  //     values.applicants[ind].documents[doc_index].documentsList.length - 1
                                  //   ].documentId !== ''
                                  //     ? '15px'
                                  //     : '50px',

                                  // pointerEvents: applicant.documents[
                                  //   values.applicants[ind].documents.length - 1
                                  // ].documentId
                                  //   ? 'all'
                                  //   : 'none',
                                  cursor: 'pointer',
                                }}>
                                {'Add another document'}
                              </Typography>
                            )}
                        </Grid>
                      </React.Fragment>
                    </>
                  );
                })}
                {ENABLE_KRA_DOCUMENT_FETCH && (
                  <KraDocument KraDocument={applicant.kraDocument} num={num + 1} />
                )}
              </>
            );
          })}
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
        </Grid>
      )}
    </Formik>
  );
}
