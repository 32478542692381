import {
  Box,
  Grid,
  Typography,
  Theme,
  Hidden,
  IconButton,
  Button,
  LinearProgress,
  CardMedia,
} from '@mui/material';
import { SubHeading } from '../investors/components';
import React, { useEffect, useState } from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useHistory, useRouteMatch } from 'react-router';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Gridstyles, Item, ItemDetails } from '../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { accessType, ClassPlanProps } from '../../redux-store/types/funds';
import { fundAccess, getPlanDetail } from '../../redux-store/actions/funds';
import { Location } from 'history';
import { formatShortDate } from '../../utils/date';
import { KeyValue } from '../ApplicationDetails/contributor';
import { applicationComparison, currencyConversion } from '../../utils/utilityFunctions';
import {
  LogsUI,
  ShowFieldUpdatedValuePopup,
} from '../UsersFundsVerification/userFundscommonComponents';

export const PlanFields = ({
  planDetails,
  oldData,
}: {
  planDetails: ClassPlanProps | Partial<ClassPlanProps>;
  oldData?: null | ClassPlanProps | Partial<ClassPlanProps>;
}): JSX.Element => {
  const [routeDialogOpen, setRouteDialogOpen] = useState<{
    open: boolean;
    propertyName: string;
    newValue: string | null | number | undefined;
    oldValue: string | null | number | undefined;
  }>({
    open: false,
    propertyName: '',
    newValue: '',
    oldValue: '',
  });

  const handleRoutePopupClose = () => {
    setRouteDialogOpen({ open: false, propertyName: '', newValue: '', oldValue: '' });
  };
  const handleRoutePopupOpen = (
    propertyName: string,
    newValue: string | null | number | undefined,
    oldValue: string | null | number | undefined
  ) => {
    setRouteDialogOpen({
      open: true,
      propertyName: propertyName,
      newValue: newValue,
      oldValue: oldValue,
    });
  };

  return (
    <>
      <KeyValue
        title={'Plan Code'}
        description={planDetails?.planCode}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData?.planCode, planDetails?.planCode) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData?.planCode, planDetails?.planCode) &&
          handleRoutePopupOpen('Plan Code', planDetails?.planCode, oldData?.planCode)
        }
      />
      <KeyValue
        title={'Plan Description'}
        description={planDetails?.planDescription}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(oldData?.planDescription, planDetails?.planDescription)
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData?.planDescription, planDetails?.planDescription) &&
          handleRoutePopupOpen(
            'Plan Description',
            planDetails?.planDescription,
            oldData?.planDescription
          )
        }
      />
      <KeyValue
        title={'Scheme Nature'}
        description={planDetails?.schemeNature}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData?.schemeNature, planDetails?.schemeNature) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData?.schemeNature, planDetails?.schemeNature) &&
          handleRoutePopupOpen('Scheme Nature', planDetails?.schemeNature, oldData?.schemeNature)
        }
      />
      {/* <KeyValue
                        title={'Registration Number'}
                        description={planDetails?.registrationNumber}
                      /> */}
      <KeyValue
        title={'Scheme Category'}
        description={planDetails?.schemeCategory}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(oldData?.schemeCategory, planDetails?.schemeCategory)
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData?.schemeCategory, planDetails?.schemeCategory) &&
          handleRoutePopupOpen(
            'Scheme Category',
            planDetails?.schemeCategory,
            oldData?.schemeCategory
          )
        }
      />
      <KeyValue
        title={'Country'}
        description={planDetails?.country}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData?.country, planDetails?.country) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData?.country, planDetails?.country) &&
          handleRoutePopupOpen('Country', planDetails?.country, oldData?.country)
        }
      />
      <KeyValue
        title={'Currency'}
        description={planDetails?.currency}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData?.currency, planDetails?.currency) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData?.currency, planDetails?.currency) &&
          handleRoutePopupOpen('Currency', planDetails?.currency, oldData?.currency)
        }
      />
      <KeyValue
        title={'Scheme Start Date'}
        description={formatShortDate(planDetails?.schemeStartDate)}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                new Date(oldData?.schemeStartDate || ''),
                new Date(planDetails?.schemeStartDate || '')
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            new Date(oldData?.schemeStartDate || ''),
            new Date(planDetails?.schemeStartDate || '')
          ) &&
          handleRoutePopupOpen(
            'Scheme Start Date',
            formatShortDate(planDetails?.schemeStartDate),
            formatShortDate(oldData?.schemeStartDate)
          )
        }
      />
      <KeyValue
        title={'Lock in Period(In years)'}
        description={planDetails?.lockInPeriod}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData?.lockInPeriod, planDetails?.lockInPeriod) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData?.lockInPeriod, planDetails?.lockInPeriod) &&
          handleRoutePopupOpen(
            'Lock in Period(In years)',
            planDetails?.lockInPeriod,
            oldData?.lockInPeriod
          )
        }
      />
      <KeyValue
        title={'Lock in Extension(In years)'}
        description={planDetails?.lockInExtension}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(oldData?.lockInExtension, planDetails?.lockInExtension)
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData?.lockInExtension, planDetails?.lockInExtension) &&
          handleRoutePopupOpen(
            'Lock in Extension(In years)',
            planDetails?.lockInExtension,
            oldData?.lockInExtension
          )
        }
      />
      <KeyValue
        title={'Face Value'}
        description={planDetails?.faceValue?.toString()}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(Number(oldData?.faceValue), Number(planDetails?.faceValue))
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(Number(oldData?.faceValue), Number(planDetails?.faceValue)) &&
          handleRoutePopupOpen('Face Value', planDetails?.faceValue, oldData?.faceValue)
        }
      />
      <KeyValue
        title={'Fund Address 1'}
        description={planDetails?.fundAddress1}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData?.fundAddress1, planDetails?.fundAddress1) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData?.fundAddress1, planDetails?.fundAddress1) &&
          handleRoutePopupOpen('Fund Address 1', planDetails?.fundAddress1, oldData?.fundAddress1)
        }
      />
      <KeyValue
        title={'Fund Address 2'}
        description={planDetails?.fundAddress2}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData?.fundAddress2, planDetails?.fundAddress2) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData?.fundAddress2, planDetails?.fundAddress2) &&
          handleRoutePopupOpen('Fund Address 2', planDetails?.fundAddress2, oldData?.fundAddress2)
        }
      />
      <KeyValue
        title={'Fund Address 3'}
        description={planDetails?.fundAddress3}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData?.fundAddress3, planDetails?.fundAddress3) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData?.fundAddress3, planDetails?.fundAddress3) &&
          handleRoutePopupOpen('Fund Address 3', planDetails?.fundAddress3, oldData?.fundAddress3)
        }
      />
      <KeyValue
        title={'Setup Fee'}
        description={planDetails?.setupFee?.toString()}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(Number(oldData?.setupFee), Number(planDetails?.setupFee))
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(Number(oldData?.setupFee), Number(planDetails?.setupFee)) &&
          handleRoutePopupOpen('Setup Fee', planDetails?.setupFee, oldData?.setupFee)
        }
      />
      <KeyValue
        title={'Custodian'}
        description={planDetails?.custodian}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData?.custodian, planDetails?.custodian) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData?.custodian, planDetails?.custodian) &&
          handleRoutePopupOpen('Custodian', planDetails?.custodian, oldData?.custodian)
        }
      />
      <KeyValue
        title={'Minimum Commitment Amount'}
        description={planDetails?.minCommitmentAmount}
        amount={currencyConversion(planDetails?.minCommitmentAmount)}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                Number(oldData?.minCommitmentAmount),
                Number(planDetails?.minCommitmentAmount)
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            Number(oldData?.minCommitmentAmount),
            Number(planDetails?.minCommitmentAmount)
          ) &&
          handleRoutePopupOpen(
            'Minimum Commitment Amount',
            currencyConversion(planDetails?.minCommitmentAmount),
            currencyConversion(oldData?.minCommitmentAmount)
          )
        }
      />
      <KeyValue
        title={'Maximum Commitment Amount'}
        description={planDetails?.maxCommitmentAmount}
        amount={currencyConversion(planDetails?.maxCommitmentAmount)}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                Number(oldData?.maxCommitmentAmount),
                Number(planDetails?.maxCommitmentAmount)
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            Number(oldData?.maxCommitmentAmount),
            Number(planDetails?.maxCommitmentAmount)
          ) &&
          handleRoutePopupOpen(
            'Maximum Commitment Amount',
            currencyConversion(planDetails?.maxCommitmentAmount),
            currencyConversion(oldData?.maxCommitmentAmount)
          )
        }
      />
      <KeyValue
        title={'Minimum Contribution Percentage'}
        description={planDetails?.minContributionPercentage?.toString()}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                Number(oldData?.minContributionPercentage),
                Number(planDetails?.minContributionPercentage)
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            Number(oldData?.minContributionPercentage),
            Number(planDetails?.minContributionPercentage)
          ) &&
          handleRoutePopupOpen(
            'Minimum Contribution Percentage',
            planDetails?.minContributionPercentage,
            oldData?.minContributionPercentage
          )
        }
      />
      <KeyValue
        title={'Investment Manager Name'}
        description={planDetails?.fundManager?.name}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                Number(oldData?.fundManagerId),
                Number(planDetails?.fundManagerId)
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            Number(oldData?.fundManagerId),
            Number(planDetails?.fundManagerId)
          ) &&
          handleRoutePopupOpen(
            'Investment Manager Name',
            planDetails?.fundManager?.name,
            oldData?.fundManager?.name
          )
        }
      />
      <KeyValue
        title={'Trustee Name'}
        description={planDetails?.trustee?.name}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(Number(oldData?.trusteeId), Number(planDetails?.trusteeId))
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(Number(oldData?.trusteeId), Number(planDetails?.trusteeId)) &&
          handleRoutePopupOpen('Trustee Name', planDetails?.trustee?.name, oldData?.trustee?.name)
        }
      />
      <KeyValue
        title={'Management Fee'}
        description={planDetails?.managementFee?.toString()}
        dispalyModifiedIcon={
          oldData
            ? !applicationComparison(
                Number(oldData?.managementFee),
                Number(planDetails?.managementFee)
              )
            : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(
            Number(oldData?.managementFee),
            Number(planDetails?.managementFee)
          ) &&
          handleRoutePopupOpen('Management Fee', planDetails?.managementFee, oldData?.managementFee)
        }
      />
      <KeyValue
        title={'PPM Link'}
        description={planDetails?.tcLink}
        dispalyModifiedIcon={
          oldData ? !applicationComparison(oldData?.tcLink, planDetails?.tcLink) : false
        }
        onInfoClick={() =>
          oldData &&
          !applicationComparison(oldData?.tcLink, planDetails?.tcLink) &&
          handleRoutePopupOpen('PPM Link', planDetails?.tcLink, oldData?.tcLink)
        }
      />
      <ShowFieldUpdatedValuePopup
        handleClose={handleRoutePopupClose}
        dialogOpen={routeDialogOpen}
        onSave={() => {
          handleRoutePopupClose();
        }}
      />
    </>
  );
};

export default function PlanDetails({
  location,
}: {
  location: Location<{ PlanId: string; fundId: string }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [access, setAccess] = useState(false);
  const { PlanId, fundId } = location.state || {};
  const [planDetails, setPlanDetails] = useState<ClassPlanProps>();

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        const { fundSetupAccess } = (await dispatch(fundAccess())) as unknown as accessType;
        if (PlanId) {
          const res = (await dispatch(getPlanDetail(PlanId))) as unknown as ClassPlanProps;
          setPlanDetails(res);
        }
        if (!isComponentActive) {
          return;
        }
        setAccess(fundSetupAccess);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}>
        <Box sx={{ p: { lg: 2, xs: 2 } }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: { lg: 'center', xs: 'flex-start' },
              flexDirection: { lg: 'row', xs: 'column' },
              justifyContent: 'space-between',
              ml: { lg: 7, xs: 2 },
              pt: { xs: 2, sm: 4 },
              mr: { lg: 7, xs: 2 },
              // pb: 1,
            }}>
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() => history.push('view-plans', { fundId: fundId, PlanId: PlanId })}>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  width: '100%',
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                    pr: 5,
                  }}>
                  Fund Setup
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() => history.push('view-plans', { fundId: fundId })}>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                }}>
                {'Plan Details'}
              </Typography>
              <Hidden smUp={true}>
                {access && (
                  <IconButton sx={{ p: 0, ml: 12 }}>
                    <CardMedia
                      component="img"
                      src="/images/edit-icon-outlined.svg"
                      alt="Edit Icon"
                      sx={{ width: 'unset' }}
                      onClick={() => history.push(`edit-plan`, { PlanId: PlanId, fundId: fundId })}
                    />
                  </IconButton>
                )}
              </Hidden>
            </Box>
            <Hidden only="xs">
              {access && (
                <IconButton sx={{ p: 0, m: 0 }}>
                  <CardMedia
                    component="img"
                    src="/images/edit-icon-outlined.svg"
                    alt="Edit Icon"
                    sx={{ width: 'unset' }}
                    onClick={() => history.push(`edit-plan`, { PlanId: PlanId, fundId: fundId })}
                  />
                </IconButton>
              )}
            </Hidden>
          </Box>

          {isLoading && <LinearProgress sx={{ ml: 5, mr: 5 }} />}
          {planDetails && (
            <>
              <Box sx={{ mr: { lg: 20, xs: 4 } }}>
                <SubHeading
                  sx={{
                    color: 'common.blue',
                    letterSpacing: 0.5 + 'px',
                    padding: { xs: '10px 10px', sm: '10px 30px' },
                    ml: { lg: 8, xs: 2 },
                  }}>
                  {'Plan Details'}
                </SubHeading>
              </Box>

              <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 }, pt: 1 }}>
                <React.Fragment>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <PlanFields planDetails={planDetails} />
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              </Grid>
              {planDetails.history && planDetails.history.length > 0 && (
                <LogsUI data={planDetails.history} />
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
