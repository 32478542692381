/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Box from '@mui/material/Box';
import { Formik } from 'formik';
import * as yup from 'yup';
import { MFTextField } from '../../lib/formik';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Hidden,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { historyForVerification } from '../../redux-store/types/api-types';
import { formatShortDateAndTime } from '../../utils/date';
import { InvalidCommentsMessage, UserFundsMaster } from '../../utils/constant';
import { defaultRegexWithSingleLine, validCommentRegex } from '../../utils/regex';
import { SubHeading } from '../investors/components';
import { DataTable } from '../DataTable';
import { useState } from 'react';
import { AdminMakerCheckerProps } from '../../redux-store/actions/userFundsVerification';

// used for displaying popup data and stay in that page or want to do navigation on click of ok
export const NavigationThrowPopup = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleClose,
  dialogOpen,
  onSave,
}: {
  handleClose: () => void;
  dialogOpen: {
    message: string;
    open: boolean;
  };
  onSave: () => void;
}): JSX.Element => {
  return (
    <Dialog
      // onClose={handleClose}
      open={dialogOpen.open}
      sx={{
        '& .MuiPaper-root': {
          minWidth: { sx: '300px', md: '400px' },
          maxWidth: { sx: '500px', md: '500px' },
          borderRadius: '8px',
          padding: '30px 30px 20px',
          textAlign: 'center',
          color: '#4f4f4f',
        },
        '& .MuiDialogContent-root': {
          textAlign: 'center',
        },
        '& .MuiDialogActions-root': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& .MuiButton-root': {
            width: '160px',
            height: '58px',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '18px',
            margin: 'auto',
            marginBottom: '20px',
          },
        },
      }}>
      {dialogOpen.message && (
        <DialogContent>
          <DialogContentText>{dialogOpen.message}</DialogContentText>
        </DialogContent>
      )}

      <DialogActions>
        <Button color="primary" variant="contained" onClick={onSave} sx={{ color: 'common.white' }}>
          OK
        </Button>
      </DialogActions>

      {/* </Box> */}
    </Dialog>
  );
};

export const LogCommentPopup = ({ msg }: { msg: string | null }): JSX.Element => {
  const [routeDialogOpen, setRouteDialogOpen] = useState({ message: '', open: false });
  const handleRoutePopupClose = () => {
    setRouteDialogOpen({ message: '', open: false });
  };
  return (
    <>
      {msg ? (
        <>
          <Typography
            component={'span'}
            sx={{ xs: { color: 'rgba(41, 49, 57, 0.7)' }, fontSize: '14px' }}>
            {msg?.substring(0, 9)}
          </Typography>
          {msg?.length > 9 && (
            <>
              ...
              <Typography
                component={'span'}
                sx={{
                  color: '#61D1D6 !important',
                  fontSize: '14px',
                  ':hover': { cursor: 'pointer', color: 'primary.main' },
                }}
                onClick={() => {
                  setRouteDialogOpen({
                    message: msg as string,
                    open: true,
                  });
                }}>
                more
              </Typography>
            </>
          )}
        </>
      ) : (
        <Typography> N/A</Typography>
      )}
      <NavigationThrowPopup
        handleClose={handleRoutePopupClose}
        dialogOpen={routeDialogOpen}
        onSave={() => {
          handleRoutePopupClose();
        }}
      />
    </>
  );
};

export const logHeaders = [
  {
    header: 'Modified By',
    valueGetter: (row: historyForVerification) => row.makerAdminName || 'N/A',
  },
  {
    header: 'Modified At',
    valueGetter: (row: historyForVerification) => formatShortDateAndTime(row.createdAt) || 'N/A',
  },
  {
    header: 'Approved/ Rejected By',
    valueGetter: (row: historyForVerification) => row.checkerAdminName || 'N/A',
  },
  {
    header: 'Approved/ Rejected At',
    valueGetter: (row: historyForVerification) =>
      formatShortDateAndTime(row.approvedAt || row.rejectedAt) || 'N/A',
  },
  {
    header: 'Maker Comments',
    renderCell: (row: historyForVerification) => <LogCommentPopup msg={row.makerComment} />,
  },
  {
    header: 'Checker Comments',
    renderCell: (row: historyForVerification) => <LogCommentPopup msg={row.checkerComment} />,
  },
  {
    header: 'Status',
    renderCell: (row: AdminMakerCheckerProps) => {
      const adminMakerCheckerStatus = UserFundsMaster[row.status || ''];
      return (
        <Typography
          className={adminMakerCheckerStatus}
          sx={{
            textDecoration: 'none',
            fontWeight: 500,
            fontSize: 14,
            color: '#B78813 !important',
            '&.Approved': {
              fontWeight: 600,
              color: 'rgba(35, 133, 63, 0.7) !important',
            },
            '&.Rejected': {
              color: '#BD2932 !important',
              fontWeight: 500,
            },
          }}>
          {UserFundsMaster[row.status || ''] || 'N/A'}
        </Typography>
      );
    },
  },
];

export const AccessComponent = ({ message }: { message: string }): JSX.Element => {
  return (
    <>
      <Typography
        sx={{
          color: 'primary.main',
          textAlign: 'center',
          mt: 2,
        }}>
        {message}
      </Typography>
      <Typography sx={{ color: 'primary.main', mt: 5 }}> Comment(s) *</Typography>
    </>
  );
};

export const RejectComponent = (): JSX.Element => {
  return (
    <>
      <Typography sx={{ color: 'primary.main' }}>
        Are you sure you want to{' '}
        <Typography component="span" sx={{ color: '#BD2932' }}>
          Reject
        </Typography>{' '}
        ?
      </Typography>
      <Typography sx={{ color: '#BD2932', mt: 5 }}> Give reason rejection *</Typography>
    </>
  );
};

export const ApproveComponent = (): JSX.Element => {
  return (
    <>
      <Typography sx={{ color: 'primary.main' }}>
        Are you sure you want to{' '}
        <Typography component="span" sx={{ color: 'green' }}>
          Approve
        </Typography>{' '}
        ?
      </Typography>
      <Typography sx={{ color: 'primary.main', mt: 5 }}> Comment(s) *</Typography>
    </>
  );
};

const initialValueForComment: { comment: string } = { comment: '' };
export const commentValidation = yup.object().shape({
  comment: yup
    .string()
    .nullable()
    .matches(validCommentRegex, InvalidCommentsMessage)
    .required('Please provide Comments'),
});

export const ConfirmationDialogWithCommentsBox = ({
  open,
  setOpen,
  component,
  onCancel,
  onSave,
}: {
  component: JSX.Element;
  open: boolean;
  setOpen: () => void;
  onCancel: () => void;
  onSave: (value: { comment: string }) => void;
}): JSX.Element => {
  return (
    <Dialog
      open={open}
      onClose={setOpen}
      sx={{
        '.MuiPaper-root ': {
          p: { xs: 1, sm: 4 },
          width: { xs: '100%', sm: '70%' },
        },
      }}>
      <Box
        sx={{
          width: { xs: '100%', sm: '85%' },
          mx: 'auto',
          '.MuiTypography-root': {
            fontSize: 18,
            fontWeight: 500,
          },
        }}>
        {component}
        <Formik
          initialValues={initialValueForComment}
          onSubmit={async (value) => onSave(value)}
          validationSchema={commentValidation}>
          {({ handleSubmit }) => (
            <Box component="form" noValidate onSubmit={handleSubmit}>
              <MFTextField
                name="comment"
                multiline
                rows={4}
                placeholder="Type your answer here..."
              />

              <Grid container sx={{ my: 3, justifyContent: 'center', gap: 1.5 }}>
                <Button
                  onClick={onCancel}
                  variant="outlined"
                  sx={{
                    fontSize: 15,
                    color: 'primary.main',
                    minWidth: 180,
                    letterSpacing: 2,
                    fontWeight: 500,
                  }}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    fontSize: 15,
                    minWidth: 180,
                    letterSpacing: 2,
                    fontWeight: 400,
                  }}
                  type="submit">
                  Continue
                </Button>
              </Grid>
            </Box>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
};

export const LogsUI = ({
  data,
  loading = false,
  textForHeading = 'Logs',
}: {
  data: historyForVerification[] | AdminMakerCheckerProps[];
  loading?: boolean;
  textForHeading?: string;
}): JSX.Element => {
  return (
    <Grid
      container
      sx={{
        pl: { xs: 2, sm: 2, lg: 10 },
        pr: { xs: 2, sm: 2, lg: 10 },
      }}>
      <SubHeading
        sx={{
          color: 'common.black',
          letterSpacing: 0.5 + 'px',
          padding: { xs: '10px 10px', sm: '10px 30px' },
        }}>
        {textForHeading}
      </SubHeading>
      {loading ? (
        <Grid xs={12}>
          <LinearProgress />
        </Grid>
      ) : (
        <Box
          sx={{
            boxShadow: '4px 4px 12px rgba(0, 0, 0, 0.1)',
            borderRadius: '5px',
            width: '100%',
            overflow: 'scroll',
          }}>
          <DataTable
            tableData={data}
            tableHeader={logHeaders}
            renderAdditionalRow={false}
            tableHeaderCustomStyles={{
              '.MuiTableCell-head': {
                fontFamily: 'Poppins, sans-serif',
                fontSize: 14,
                fontWeight: 500,
                color: 'primary.main',
                whiteSpace: 'normal',
              },
            }}
            rowCustomStyles={{
              '&:last-child td, &:last-child th': { border: 0 },
            }}
            // rowCustomStyles={{
            //   '.MuiTableCell-root': {
            //     py: '8px',
            //     px: '8px',
            //   },
            // }}
            boxStyles={{
              minHeight: '0px',
              minWidth: { sm: '100%', lg: 650 },
              maxWidth: { sm: 0, lg: '100%' },
            }}
          />
        </Box>
      )}
    </Grid>
  );
};

export const ShowFieldUpdatedValuePopup = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleClose,
  dialogOpen,
  onSave,
}: {
  handleClose: () => void;
  dialogOpen: {
    open: boolean;
    propertyName: string;
    newValue: string | number | null | undefined;
    oldValue: string | number | null | undefined;
  };
  onSave: () => void;
}): JSX.Element => {
  return (
    <Dialog
      // onClose={handleClose}
      open={dialogOpen.open}
      sx={{
        '.MuiPaper-root ': {
          p: 4,
          width: { xs: '100%', sm: '70%' },
          pt: 2,
        },
      }}>
      <Box
        sx={{
          width: { xs: '100%', sm: '90%' },
          mx: 'auto',
          '.MuiTypography-root': {
            textAlign: 'center',
          },
          '.MuiFormControl-root': { my: 1 },
          px: { xs: '0', sm: 1 },
        }}>
        <Typography
          sx={{
            color: '#0D2762',
            position: 'relative',
            bottom: '20px',
            fontSize: '20px',
            fontWeight: 500,
            mt: 3,
          }}>
          {'Field value updated details'}
        </Typography>
        <Hidden only="xs">
          <Box
            sx={{
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
              borderRadius: '10px',
            }}>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: '20px !important' }}>
                      <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                        {'Property Name'}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: '20px !important' }}>
                      <Typography
                        sx={{
                          fontSize: 16,
                          fontWeight: 500,
                        }}>
                        {'New Value'}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: '20px !important' }}>
                      <Typography
                        sx={{
                          fontSize: 16,
                          fontWeight: 500,
                        }}>
                        {'Old Value'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{
                      '&:last-child td': { border: 0 },
                      height: '20px !important',
                    }}>
                    <TableCell
                      align="right"
                      sx={{
                        height: '60px !important',
                        overflowWrap: 'anywhere',
                      }}>
                      <Typography
                        align="left"
                        sx={{
                          py: 0.5,
                          px: 1.2,
                          fontSize: '15px',
                        }}>
                        {dialogOpen.propertyName}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        height: '60px !important',
                        overflowWrap: 'anywhere',
                      }}>
                      <Typography
                        align="left"
                        sx={{
                          bgcolor: 'rgb(203,245,221,0.5)',
                          py: 0.5,
                          px: 1.2,
                          borderRadius: '5px',
                          fontSize: '15px',
                        }}>
                        {dialogOpen.newValue?.toString() || 'N/A'}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        height: '60px !important',
                        overflowWrap: 'anywhere',
                      }}>
                      <Typography
                        align="left"
                        sx={{
                          bgcolor: 'rgb(251,217,211,0.5)',
                          py: 0.5,
                          px: 1.2,
                          borderRadius: '5px',
                          fontSize: '15px',
                        }}>
                        {dialogOpen.oldValue?.toString() || 'N/A'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Hidden>
        <Hidden smUp={true}>
          <Grid
            container
            // columnSpacing={1}
            sx={{
              border: '1px solid #00D9DA',
              p: 1.25,
              // ml: 0,
              borderRadius: 1.25,
              mb: 1.25,
              rowGap: 1.25,
              padding: { xs: '10px', sm: '55px' },
              '.MuiGrid-item': {
                px: 1,
              },
              '.MuiTypography-root': {
                fontSize: { xs: 10, sm: 16 },
                fontWeight: 500,
                maxWidth: '204px',
                overflowWrap: 'anywhere',
              },
              '& .MuiTypography-h6': {
                fontFamily: 'Poppins, sans-serif',
                color: 'primary.main',
              },
              '& .MuiTypography-body1': {
                color: 'rgba(41, 49, 57, 0.7)',
              },
            }}>
            <Grid xs={6} sm={4}>
              <Typography variant="h2" sx={{ fontSize: '16px', fontWeight: 500, mb: 1 }}>
                {'Property Name'}
              </Typography>
              <Typography
                sx={{
                  py: 0.5,
                  px: 1.2,
                  fontSize: '15px',
                }}>
                {dialogOpen.propertyName}
              </Typography>
            </Grid>
            <Grid xs={6} sm={4}>
              <Typography variant="h2" sx={{ fontSize: 16, fontWeight: 500, mb: 1 }}>
                {'New Value'}
              </Typography>
              <Typography
                sx={{
                  bgcolor: 'rgb(203,245,221,0.5)',
                  py: 0.5,
                  px: 1.2,
                  borderRadius: '5px',
                  fontSize: '15px',
                }}>
                {dialogOpen.newValue?.toString() || 'N/A'}
              </Typography>
            </Grid>
            <Grid xs={6} sm={4}>
              <Typography variant="h2" sx={{ fontSize: 16, fontWeight: 500, mb: 1 }}>
                {'Old Value'}
              </Typography>
              <Typography
                sx={{
                  bgcolor: 'rgb(251,217,211,0.5)',
                  py: 0.5,
                  px: 1.2,
                  borderRadius: '5px',
                  fontSize: '15px',
                }}>
                {dialogOpen.oldValue?.toString() || 'N/A'}
              </Typography>
            </Grid>
          </Grid>
        </Hidden>
        <Grid container sx={{ my: 3, justifyContent: 'center', gap: 1.5 }}>
          <Button
            onClick={onSave}
            variant="contained"
            sx={{
              fontSize: 15,
              minWidth: 180,
              letterSpacing: 2,
              fontWeight: 500,
            }}>
            Ok
          </Button>
        </Grid>
      </Box>
    </Dialog>
  );
};
